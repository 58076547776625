import React from "react";

export const Bannersection = () => {
  return (
    <>
      <img
        className="w-screen"
        src={require("../../../imagenes/imagenesbanner/cloro-banner.jpg")}
      />
    </>
  );
};
