import React from "react";
import { Horario } from "../../atoms/atomos-navbar1/horario/Horario";

export const Horariomolecula = () => {
  return (
    <div className="mt-2 font-baloo">
      <Horario />
    </div>
  );
};
