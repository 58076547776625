import React from "react";
import { Carrusel } from "../../carrusel/Carrusel";

export const Header3 = () => {
  return (
    <>
      <Carrusel />
    </>
  );
};
