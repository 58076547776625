import React from "react";
import { FaShoppingBag } from "react-icons/fa";

export const Shoppinglogo = () => {
  return (
    <>
      <FaShoppingBag size={35} />
    </>
  );
};
