export const Categorias = [
  {
    id: "1",
    key: 1,
    img: require("../imagenes/Super-cleaner.png"),
    main_category: "Detergentes",
    sinboton: "false",
    type: "Liquido",
    type2: "En Polvo",
    description: "Le da un brillo especial a tu ropa",
    link: "/detergente_liquido",
    link2: "/detergente_en_polvo",
  },
  {
    id: "2",
    key: 2,
    img: require("../imagenes/Super-cleaner.png"),
    main_category: "Lavaplatos",
    sinboton: "false",
    type: "Liquido",
    type2: "En Crema",
    description: "Facilitando tu vida",
    link: "/lavaplatos_liquido",
    link2: "/lavaplatos_crema",
  },
  {
    id: "3",
    key: 3,
    type: "Liquido",
    sinboton: "true",
    img: require("../imagenes/Super-cleaner.png"),
    main_category: "Desinfectante",
    link: "/desinfectante_liquido",
    description: "Calidad asegurada",
  },
];
