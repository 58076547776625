import React from "react";
import { FaMailBulk } from "react-icons/fa";

export const Maillogo = () => {
  return (
    <>
      <FaMailBulk size={30} className="ml-20" />
    </>
  );
};
